@font-face {
  font-family: "SofiaPro-Medium";
  src: url("./webFonts/SofiaProMedium/font.woff2") format("woff2"),
    url("./webFonts/SofiaProMedium/font.woff") format("woff");
}
@font-face {
  font-family: "SofiaPro-Bold";
  src: url("./webFonts/SofiaProBold/font.woff2") format("woff2"),
    url("./webFonts/SofiaProBold/font.woff") format("woff");
}
* {
  font-family: "SofiaPro-Medium", sans-serif;
  color: #5a7794;
  box-sizing: border-box;
}
h3 {
  font-weight: normal;
}
.c-footer {
  padding: 1.4rem 0;
  border-radius: 10px;
  margin-top: 10px;
  background: white;
  background-position: center;
  background-size: cover;
  margin-bottom: 1rem;
  width: 100% !important;
}

.c-footer .new-header-v2 {
  font-weight: 400 !important;
  margin-top: 0;
}

.c-footer-icon * {
  fill: #0051a2;
  font-size: 3rem;
  color: #0051a2;
}

.c-footer .btn-accent {
}

.c-footer > div > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.c-footer-icon {
  width: 20%;
}

.c-footer-icon > * {
  padding: 10px;
  border-radius: 50px;
  width: 60px;
  color: #0051a2;
  height: 60px;
  background-color: #0051a211;
  display: flex !important;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-size: 1.5rem !important;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.c-footer-data {
  width: 80%;
  padding-left: 1rem;
}

.c-footer-data * {
  letter-spacing: 0px !important;
  font-size: 14px !important;
}

.zeetabs-content-containers {
  padding: 1rem 0;
}

.zeetabs * {
  box-sizing: border-box;
}

.hide-scrollbar {
  height: 68px;
  overflow: hidden;
  position: relative;
}

.scroll-button {
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: white;
  z-index: 2;
  color: #5a7794;
  box-shadow: 0 2px 10px #ddd;
  top: 50%;
  transition: all 0.3s ease;
  transform: translateY(-50%) scale(0);
}
.scroll-button-show {
  transform: translateY(-50%) scale(1);
}
.scroll-button:hover {
  cursor: pointer;
}

.hide-scrollbar .left-shadow {
  width: 80px;
  height: 100%;
  z-index: 1;
  content: "";
  background-image: linear-gradient(to right, white, white);
  position: absolute;
  left: 0rem;
  box-shadow: 20px 0 25px white;
  top: 50%;
  transform: translateX(0) translateY(-50%) scale(0);
}
.hide-scrollbar .right-shadow {
  width: 80px;
  height: 100%;
  z-index: 1;
  content: "";
  background-image: linear-gradient(to left, white, white);
  position: absolute;
  right: 1rem;
  box-shadow: -20px 0 25px white;
  top: 50%;
  transform: translateX(50%) translateY(-50%) scale(0);
}
.hide-scrollbar .right-shadow-show {
  transform: translateX(50%) translateY(-50%) scale(1);
}
.hide-scrollbar .left-shadow-show {
  transform: translateX(0%) translateY(-50%) scale(1);
}

.scroll-button.right {
  right: 1rem;
}

.scroll-button.left {
  left: 1rem;
}

.zee-tabs-v2 .zeetabs-button-container:after {
  display: none;
}

.zeetabs-button-container {
  margin: 0 auto;
  padding-left: 1rem;
  margin-top: 0.5rem;
  overflow-y: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  padding-top: 1rem;
  scroll-behavior: smooth;
  position: relative;

  /* background: white; */
}
.zeetabs-button-container:after {
  position: absolute;
  content: "";
  z-index: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #eff6ff;
}

.tab-button {
  width: auto;
  padding: 12px 2rem 12px;
  border-radius: 40px 40px 0 0;
  height: auto;
  font-size: 14px;
  background: #f6f6f6;
  /* background: none; */
  position: relative;
  z-index: 0;
  color: #5a7794;
  border: none;
  margin-right: 1rem;
  border: none;
  border-bottom: none;
  cursor: pointer;
  font-weight: normal;
  transition: all 0.3s ease-in-out;
}
.tab-button svg[data-tip] {
  width: 25px !important;
  height: 25px !important;
}
.tab-button > * {
  white-space: nowrap;
  font-weight: normal;
  font-size: 14px !important;
}

.tab-button:hover {
  /* filter: brightness(0.9); */
}

.tab-button.active {
  color: #0051a2;
  border-color: transparent;
  background: #ffffff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  z-index: 11;
}
.tab-button.active *:not(.fa) {
}
.tab-button.active:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -1px;
  width: 100%;
  background-color: white;
  height: 2px;
}
.tab-button.active * {
  fill: #0051a2 !important;
  color: #0051a2 !important;
}

.tab-button.disabled {
  color: #aaa;
  background: #ddd;
  cursor: default;
}

.tab-button.disabled:hover {
  /* filter: brightness(1); */
}

.zeetabs-content {
  opacity: 0;
  height: 0;
  overflow-y: hidden;
}

.zeetabs-content.active {
  opacity: 1;
  height: auto;
  overflow-y: visible;
}
.zee-tabs-v2 .zeetabs-content-containers {
  padding: 0;
  border: none;
}

.zee-tabs-v2 .zeetabs-button-container {
  justify-content: center;
  overflow-y: hidden;
  position: relative;
}

.zee-tabs-v2 .zeetabs-button-container:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 10px;
  z-index: 1;
  background: white;
}

.zee-tabs-v2 .zeetabs-button-container .tab-button {
  width: auto;
  padding: 0rem 0.3rem;
  border-radius: 4px 4px 0 0;
  height: 45px;
  font-size: 1rem;
  background: none;
  position: relative;
  z-index: 0;
  max-width: 222px;
  min-width: 100px;
  color: #5a7794;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 1rem;
  /* border: 1px solid #ddd; */
  border-bottom: none;
  cursor: pointer;
  font-weight: normal;
  border-bottom: 5px solid transparent;
  font-weight: normal;
}

.zee-tabs-v2 .zeetabs-button-container .tab-button::after {
  position: absolute;
  content: "";
  bottom: -3px;
  width: 10px;
  height: 10px;
  background: none;
  transform: translateX(-50%) rotate(45deg) translateY(100%);
  z-index: 1;
  left: 50%;
}

.zee-tabs-v2 .zeetabs-button-container .tab-button.active {
  border-bottom: 5px solid #0051a2;
  font-weight: bold;
  color: #5a7794;
  box-shadow: none;
  min-width: 100px;
  max-width: 100%;
  overflow: visible;
  text-overflow: initial;
}

.zee-tabs-v2 .zeetabs-button-container .tab-button.active::after {
  background: #0051a2;
  transform: translateX(-50%) rotate(45deg) translateY(7px);
}
.zee-tabs-v2 .zeetabs-button-container .tab-button.active * {
  color: inherit !important;
}

.zee-tabs-v2.zee-tabs-v3 .zeetabs-button-container .tab-button.active {
  border-bottom: 5px solid #0051a2;
  font-weight: bold;
  color: #5a7794;
}

.zee-tabs-v2.zee-tabs-v3 .zeetabs-button-container .tab-button.active::after {
  background: #0051a2;
  transform: translateX(-50%) rotate(45deg) translateY(7px);
}
.zee-tabs-v5 .zeetabs-button-container .tab-button.active {
  border-bottom: 3px solid #284e75;
  font-weight: bold;
  color: #5a7794;
  box-shadow: none;
  letter-spacing: 5px;
  white-space: nowrap;
}
.zee-tabs-v5 .tab-button {
  border-bottom: 3px solid transparent;
  font-weight: bold;
  color: #5a7794;
  box-shadow: none;
  background: transparent;
  letter-spacing: 5px;
  text-transform: uppercase;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.bullet-blue {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 3px;
  margin-right: 10px;
  background: #0051a2;
  margin-top: 5px;
}

.bullet-number {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 3px;
  background: #eff6ff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #5a7794 !important;
  font-size: 0.9rem !important;
  font-weight: bold;
}

.bullet-white {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 3px;
  margin-right: 10px;
  background: #fff;
  margin-top: 5px;
}

.list-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 0;
  text-align: left;
}

.list-container p {
  padding-top: 0;
  padding-bottom: 0;
}

.list-container-2 p {
  padding-top: 0;
  padding-bottom: 0;
}

.list-container-3 p {
  padding-top: 0;
  padding-bottom: 0;
}

.bullet-dash {
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 10px;
  color: #0051a2;
  font-size: 0.9rem !important;
}

.bullet-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  background: #aaa;
  margin-top: 9px;
}

.list-container-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 3px 0;
  text-align: left;
  padding-left: 20px;
}

.list-container-3,
.list-container-5,
.list-container-space {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 3px 0;
  text-align: left;
  padding-left: 50px;
  color: #5a7794;
  font-size: 1rem;
}

.list-container-5 {
  padding-left: 70px;
}

.list-container-space {
  padding-left: 10px;
}

.list-section {
  margin-bottom: 17px;
}
.list-container .fa-arrow-circle-right {
  margin-top: 4px;
  margin-right: 10px;
}
.react-simple-expander > div:last-child {
  transition: all 0.3s ease-in-out;
  transform: translateY(-20px);
  opacity: 0;
  pointer-events: none;
  height: 0px;
  animation: expand 0.2s ease-out 1s 1;
}
@keyframes expand {
  from {
    height: 100%;
  }
  to {
    height: 0px;
  }
}
.react-simple-expander-expanded > div:last-child {
  transition: all 0.3s ease-in-out;
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
  height: 100%;
}

.react-simple-expander .expander-header {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  z-index: 1;
  position: relative;
}
.react-simple-expander .expander-header:last-child {
  border-bottom: none;
}

.react-simple-expander .expander-header > span:first-child {
  color: #284e75;
  font-size: 1.5rem;
}

.react-simple-expander .expander-header > span:last-child {
  font-size: 1.5rem;
  color: #aaa;
  width: 30px;
  display: flex;
  font-weight: lighter;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transform: rotate(45deg);
  transition: all 0.2s ease-in-out;
}
.react-simple-expander-expanded .expander-header > span:last-child {
  transform: rotate(0deg);
}

.footer {
  padding-top: 4rem;
  padding: 4rem 1rem 0 1rem;
  box-sizing: border-box;
}

.footerLinks a {
  cursor: pointer;
  color: #4e6378 !important;
  white-space: normal;
  line-height: 1.5;
}

.footerLinks a:hover {
  text-decoration: underline;
}

.footerLinks ul {
  list-style: none;
  padding: 0;
  padding-left: 0 !important;
}

.footer h3 {
  font-weight: normal !important;
  font-size: 1rem !important;
  font-family: "SofiaPro-Bold";
}

.footerLinks ul li {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.2rem;
  color: #284e75;
}

.social-icons a {
  margin-top: 15px !important;
  transition: all 0.2s ease;
  color: #4e6378 !important;
}

.social-icons a:hover {
  color: #0051a2 !important;
}

.social-icons a:first-child {
  margin-left: 0 !important;
}
.menu-drawer {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 6rem;
  background-color: white;
  padding: 5rem 3rem;
  transform: translateX(150%);
  transition: all 0.3s ease;
}
.menu-drawer .close {
 position: absolute;
    right: 1rem;
    font-size: 1.3rem;
    opacity: 0.3;
    top: 1rem;
}
.drawer-open {
  transform: translateX(0%);
}
  .org-nav-bottom {
    margin-top: 1rem;
  }
@media only screen and (max-width: 600px) {
  .footerLinks ul li  {
    text-align: center;
  }
  .org-section {
    padding: 2rem 1rem;
  }
  .org-nav-bottom {
    display:flex;
    flex-direction: column;
  }
  .org-nav-bottom  .org-nav-element {
    margin: 1rem 0;
  }
  .org-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 2rem 0;
    justify-content: space-around;
  }
  .org-section h1 {
    font-size: 1.2rem !important;
    letter-spacing: 7px !important;
  }
  .org-card h1,
  .org-card-vert h1 {
    font-size: 1rem !important;
    letter-spacing: 6px !important;
  }
  .org-card {
    padding-left: 1rem;
  }
  .org-section {
    padding: 2rem 10px;
  }
  .org-section .text {
    padding: 0;
  }
  .zeetabs-content-containers {
  }
  .zeetabs-button-container {
    overflow-x: auto;
    padding: 0 1rem;
    padding-top: 1rem;
    padding-left: 0;
    position: relative;
  }

  .tab-button {
    padding: 8px 2rem;
  }
  .radio-group > div {
    width: 100%;
  }
  .zeetabs-content .zeetabs-button-container {
    max-width: 100%;
  }
}
.react-simple-expander > div:last-child {
  transition: all 0.3s ease-in-out;
  transform: translateY(-20px);
  opacity: 0;
  pointer-events: none;
  height: 0px;
  animation: expand 0.2s ease-out 1s 1;
}
@keyframes expand {
  from {
    height: 100%;
  }
  to {
    height: 0px;
  }
}
.react-simple-expander-expanded > div:last-child {
  transition: all 0.3s ease-in-out;
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
  height: 100%;
}

.react-simple-expander .expander-header {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  z-index: 1;
  position: relative;
}
.react-simple-expander .expander-header:last-child {
  border-bottom: none;
}

.react-simple-expander .expander-header > span:first-child {
  color: #284e75;
  font-size: 1.5rem;
}

.react-simple-expander .expander-header > span:last-child {
  font-size: 1.5rem;
  color: #aaa;
  width: 30px;
  display: flex;
  font-weight: lighter;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transform: rotate(45deg);
  transition: all 0.2s ease-in-out;
}
.react-simple-expander-expanded .expander-header > span:last-child {
  transform: rotate(0deg);
}

.extra-content {
  padding-left: 1rem;
}

.extra-content-open {
  display: flex;
}
div.zeehaus-card.card-light {
  padding-left: 0 !important;
  padding-right: 0 !important;
  box-shadow: none;
}
div.zeehaus-card div.card-light {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.extra-content-open-btn {
}

.extra-content-close-btn {
}
.extra-content-open .org-card-vert div {
  padding: 0;
}

@media only screen and (max-width: 500px) {
  .logo-extra-big {
    justify-content: center;
  }
  .zee-tabs-v2 .zeetabs-button-container {
    padding: 0 0;
  }
  .zeetabs-button-container {
    overflow-x: auto;
    padding: 0 1rem;
    padding-left: 5px;
    max-width: 100%;
    padding-top: 10px !important;
    justify-content: flex-start;
    display: flex;
  }
  .zee-tabs-v5 .tab-button {
    border-bottom: 3px solid transparent;
    font-weight: bold;
    color: #5a7794;
    box-shadow: none;
    background: transparent;
    letter-spacing: 5px;
    text-transform: uppercase;
    white-space: nowrap;
    display: inline-block;
  }
  .zeetabs-content.active {
  }
  .tab-button {
  }
  .radio-group > div {
    width: 100%;
  }
}

@media screen and (max-width: 1250px) {
  .c-footer-icon > * {
    width: 50px;
    height: 50px;
    font-size: 1.5rem !important;
    margin-right: 15px;
  }
  .c-footer .btn-accent {
    font-size: 12px !important;
  }
  .c-footer-icon {
    font-size: 1.7rem !important;
  }
}
