.org-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0;
}
.org-nav-top {
}
.org-nav-top img {
  width: 450px;
  margin-bottom: 0rem;
}
.org-nav-bottom {
  display: flex;
}
.org-nav-element {
  margin: 0 1rem;
  position: relative;
}
.org-nav-element div {
  display: none;
  position: absolute;
  left: 0;
  box-shadow: 2px 2px 10px 0px #eee;
  top: 39px;
  height: auto;
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
  border-top: 5px solid #5a7794;
  flex-direction: column;
  min-width: 300px;
}

.org-nav-element a {
  text-decoration: none;
  font-size: 12px;
  padding: 6px 0;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.org-nav-element:hover div {
  display: flex;
  background-color: white;
  z-index: 1;
}
.org-nav-element:hover div::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  transform: translateY(-100%);
  z-index: 0;
  height: 40px;
  content: '';
}
.org-nav-element:hover a {
  color: #0051af;
}
.org-nav-element:hover div {
  color: #0051af;
}
.org-nav-element:hover div a {
  color: #5a7794;
}
.org-nav-element:hover div a:hover {
  color: #0051af;
}

.org-section {
  box-sizing: border-box;
  padding: 2rem 2rem;
}
.org-section-img {
  width: 100%;
  height: 100vh;
  border-radius: 20px;
  object-fit: cover;
}
.org-section .text {
  padding: 1rem;
}
.org-section h1 {
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 12px;
  line-height: 1.5;
  color: #284e75;
  margin-bottom: 0;
  text-align: left;
  margin-top: 1rem;
}
.center {
  text-align: center !important;
}
.org-section h2 {
  color: #284e75;
  margin-bottom: 1rem;
}
.gray-line {
  display: inline-flex;
  height: 2px;
  width: 20%;
  background-color: #5a7794;
  margin-bottom: 0rem;
}
.org-card {
  display: flex;
  align-items: center;
  width: 96%;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;
  /* border: 1px solid; */
  background: #f8f8f8;
  padding-left: 2rem;
  min-height: 350px;
  position: relative;
  box-sizing: border-box;
}
.org-card h1,
.org-card-vert h1 {
  font-size: 1.2rem;
  letter-spacing: 4px;
}
.org-card-vert .coin-back {
  position: absolute;
    right: 0;
    width: 100%;
    top: -100px;
    height: 400px;
    display: block;
    opacity: 0.08;
    object-fit: contain;
}
.org-card div {
  width: 60%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 1rem;
  text-align: left;
  padding: 1rem 2rem 1rem 1rem;
  box-sizing: border-box;
}
.org-card img,
.org-card-vert img {
  width: 40%;
  display: inline-flex;
  object-fit: cover;
  font-size: 1.4rem;
  letter-spacing: 4px;
  height: 200px;
  border-radius: 20px 0 0 20px;
}
.org-card-vert {
  display: flex;
  position: relative;
  align-items: center;
  width: 96%;
  justify-content: flex-end;
  border-radius: 20px;
  overflow: hidden;
  /* border: 1px solid; */
  background: #f8f8f8;
  padding-left: 0rem;
  flex-direction: column-reverse;
  min-height: 350px;
  height: 100%;
  box-sizing: border-box;
}
.org-card-vert > div {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 1rem;
  text-align: left;
  padding: 2rem;
  box-sizing: border-box;
}
.org-card-vert img {
  width: 80%;
  border-radius: 0 0 20px 20px;
}
.extra-content-open {
  text-align: left;
}
.link {
  text-decoration: none;
  color: #0051a2;
  cursor: pointer;
  background: none;
  border: none;
  font-size: inherit;
  font-weight: normal;
  padding: 0;
  font-size: inherit;
}

.popup-dialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1501;
}
.popup-animation .popup-content {
  transition: all 0.5s ease;
  animation: popup-from-bottom 450ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

.trademark {
  color: #5a7794;
    margin-top: -13px;
    border: 2px solid;
    width: 19px;
    height: 19px;
    line-height: 1.3;
    border-radius: 50%;
    margin-left: 0px;
    text-align: center;
    font-size: 0.7rem;
}
@keyframes popup-from-bottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
.popup-children {
  opacity: 0;
  animation: popup-content-appear 450ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}
@keyframes popup-content-appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.property-photos .image-gallery-slide img {
  width: 100%;
  max-height: 50vmin;
  object-position: center;
  object-fit: cover;
}
.popup-content {
  background: white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  z-index: 2;
}
.popup-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: #00050baa;
}
.popup-header {
  position: relative;
}

.dialog-children .popup-header > .popup-head-container {
  padding-left: 0;
}
.popup-header > .popup-head-container {
  padding: 1rem 1rem;
  border-bottom: 1px solid #ddd;
  overflow-x: hidden;
}

.popup-header .popup-back-button {
  float: right;
  padding: 0;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 11px;
  top: 62%;
  background: white;
  color: #5a7794;
  line-height: 0;
  font-size: 2rem;
  transform: translateY(-56%);
  cursor: pointer;
  /* box-shadow: 0 5px 15px rgba(0,0,0,0.2); */
  z-index: 1;
}
.popup-header .popup-close-button {
  width: 60px;
  float: right;
  border: none;
  padding: 0;
  height: 60px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -0;
  top: 50%;
  background: white;
  color: #5a7794;
  line-height: 0;
  font-size: 2rem;
  transform: translateY(-56%);
  cursor: pointer;
  /* box-shadow: 0 5px 15px rgba(0,0,0,0.2); */
  z-index: 0;
}

.popup-subHead {
  color: #5a7794;
}

.popup-head {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: -5px;
}

.popup-children {
  overflow-y: auto;
  padding: 1rem;
  max-height: 88vh;
}
.popup-children .popup-height {
  overflow-y: auto;
  padding: 1rem;
  max-height: 120vh;
}

.popup-children .popup-height .container {
  padding-left: 0 !important;
  width: 100%;
}

.overflow-hidden {
  overflow: hidden !important;
}

@media only screen and (max-width: 600px) {
  .org-card {
    flex-direction: column-reverse;
    
  }
  .org-card img {
    width: 100%;
  }
  .org-nav-top img {
    width: 250px;
  }
  .org-card div {
    width: 100%;
    padding-left: 0;
  }
  .org-section-img {
    height: 100vmin !important;
  }
  .org-nav-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 2rem;
  }
  .popup-dialog:after {
    background: none;
  }
}
