body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main-background {
 content: '';
    position: fixed;
    filter: grayscale(1);
    opacity: 0.1;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
  /* background-image: url('./Background.jpg');
  background-position: center;
  background-size: cover; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
